import React from 'react'
import { MDBCol, MDBRow, MDBContainer } from 'mdbreact'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhoneAlt, faFax, faEnvelope, faInfoCircle } from '@fortawesome/pro-solid-svg-icons'

class CardIconsOffices extends React.Component {
  render() {
    return (
       <section className="bg-light-blue">
        <MDBContainer>
        <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
          HQ contacts</h2>
          <MDBRow>
            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={"../../static/assets/bragroll-support.png"}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                    <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Technical support
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        Visit our{' '}
                        <a href="https://support.macro4.com" className="effect">
                          customer portal
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>
            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                     <StaticImage
                      src={"../../static/assets/sales-marketing-media.png"}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                  <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Sales, marketing and media
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:market@macro4.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          market@macro4.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                    <StaticImage
                      src={"../../static/assets/bragroll-a-global-partner.png"}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                  <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Partner program
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul px-0 px-md-2">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:partnerquestions@macro4.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          partnerquestions@macro4.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                      <StaticImage
                      src={"../../static/assets/finance-contracts.png"}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                  <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-2">
                      Finance and contracts
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:contracts@macro4.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          contracts@macro4.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>

            <MDBCol lg="4" md="6" className="pb-30 d-flex">
              <div className="card flex-fill outline card-hover">
                <div
                  className="card-body d-flex flex-row align-items-center"
                  style={{ minHeight: '150px', maxHeight: '150px' }}
                >
                  <div
                    style={{
                      minWidth: '85px',
                      maxWidth: '85px',
                    }}
                  >
                      <StaticImage
                      src={"../../static/assets/team.png"}
                      className="mr-1"
                      alt="alt image"
                    />
                  </div>
                  <div className="d-flex align-items-center">
                  <h3 className="font-alt font-w-600 letter-spacing-1 text-xs-large text-large pt-3 pl-3">
                      Career opportunities
                    </h3>
                  </div>
                </div>

                <div className="card-body-prods">
                  <ul className="fa-ul text-medium">
                    <>
                      <li className="pb-3">
                        <span className="fa-li">
                          <FontAwesomeIcon
                            icon={faEnvelope}
                            className="ml-2 mr-5 va-m"
                          />
                        </span>
                        <a
                          href={'mailto:humanresources@macro4.com'}
                          className="effect"
                          rel="noopener"
                          target="_blank"
                        >
                          humanresources@macro4.com
                        </a>
                      </li>
                    </>
                  </ul>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        </section>
    )
  }
}

export default CardIconsOffices
